import { AxiosError } from "axios";
import { pathDivider } from "consts";
import { CreateToastType } from "providers";

type ToastType = {
  error: CreateToastType;
  warning: CreateToastType;
};
export class Errors {
  error: CreateToastType;
  warning: CreateToastType;
  t: Function;

  constructor({ error, warning }: ToastType, translation: Function) {
    this.error = error;
    this.warning = warning;
    this.t = translation;
  }

  defaultError = (reason: AxiosError) => {
    const paths = reason.request.responseURL.split(pathDivider);
    const details = `\n${reason.response?.status} - ${paths[paths.length - 1]}`;

    switch (reason.response?.status) {
      case 401:
        this.warning({
          description: this.t("alerts.unauthorizedAction") + details,
        });
        break;
      case 500:
        this.error({
          description: this.t("errors.interruptedAction") + details,
        });
        break;
      default:
        this.error({
          description: this.t("errors.unforeseen") + details,
        });
        break;
    }
  };

  defaultSyncError = (response: Error) => {
    const cause = response.cause as XMLHttpRequest;
    const paths = cause.responseURL.split(pathDivider);

    this.error({
      description: `${this.t("errors.unforeseen")}\n${cause.status} - ${
        paths[paths.length - 1]
      }`,
    });
  };
}
